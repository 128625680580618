import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "./tag.less"

const Tag = ({ children, title, link, ...tagProps }) => {
  return (
    <Link
      to={link? `/tags/${link}` : ''}  
      className="tag"
      aria-label={title}
      {...tagProps}
    >
      #{children}
    </Link>
  )
}

Tag.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
}

export default Tag
