import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Tags from "../components/tags/tags"

const Post = ({ data: { mdx } }) => {
  return (
    <Layout
      heroFade
      heroCover={mdx.frontmatter.cover}
      heroContent={
        <>
          <h1 className="huge">
            <strong>{mdx.frontmatter.title}</strong>
          </h1>
          <h3>{mdx.frontmatter.date}</h3>
          <Tags tags={mdx.frontmatter.tags} />
        </>
      }
    >
      <SEO title={mdx.frontmatter.title} keywords={mdx.frontmatter.tags} />
      <article className="post">
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        tags
        cover {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
