import React from "react"
import PropTypes from "prop-types"

import Tag from "../ui/tag/tag"

import "./tags.less"

const Tags = ({ tags }) => {
  return (
    <section className="tags">
      {tags.map((tag, index) => (
        <Tag key={index} title={tag} link={tag}>
          {tag}
        </Tag>
      ))}
    </section>
  )
}

Tags.propTypes = {
  tags: PropTypes.array,
}

export default Tags
